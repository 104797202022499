<template>
  <a-modal
    v-model="show"
    :centered="true"
    :keyboard="false"
    :footer="null"
    :width="1200"
    @cancel="handleCancleClick"
    title="职位详情信息"
  >
    <a-row style="margin-bottom: 10px" >
      <a-col :span="12">
        <p><span><a-icon type="star"/>职位名称：</span><span>{{jobData.jobName ? jobData.jobName : '暂无'}}</span></p>
        <p><span><a-icon type="star"/>职位类别：</span><span>{{jobType}}</span></p>
        <p><span><a-icon type="star"/>部门：</span><span>{{jobData.deptName ? jobData.deptName : '暂无'}}</span></p>
      </a-col>
      <a-col :span="12">
        <p><span><a-icon type="star"/>招聘人数：</span><span>{{jobData.headCount ? `${jobData.headCount} 人` : '暂无'}}</span></p>
        <p><span><a-icon type="star"/>是否急招：</span><span>{{isWorry}}</span></p>
        <p><span><a-icon type="star"/>职位状态：</span><span>{{status}}</span></p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <p>
          <span><a-icon type="star"/>职位标签：</span>
          <a-tag style="margin: 0 5px 5px" v-for="(data, index) of labels" :key="index">
            {{ data }}
          </a-tag>
        </p>
        <p>
          <span><a-icon type="star"/>职位亮点：</span><br/>
          <span class="info" v-html="jobData.highlights ? jobData.highlights : '暂无'"></span>
        </p>
        <p>
          <span><a-icon type="star"/>职位描述：</span><br/>
          <span class="info" v-html="jobData.jobDescription ? jobData.jobDescription : '暂无'"></span>
        </p>
        <p>
          <span><a-icon type="star"/>岗位要求：</span><br/>
          <span class="info" v-html="jobData.requirements ? jobData.requirements : '暂无'"></span>
        </p>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
export default {
  name: "JobInfo",
  props: {
    infoVisiable: {
      require: true,
      type: Boolean,
      default: false
    },
    jobData: {
      require: true,
      type: Object,
      default: {}
    }
  },
  data() {
    return {

    }
  },
  computed: {
    show: {
      get: function () {
        return this.infoVisiable
      },
      set: function () {}
    },
    jobType: {
      get: function () {
        let type = "";
        switch(this.jobData.jobType) {
          case '1':
            type = "SR";
            break;
          case '2':
            type = "Hourly";
            break;
          case '3':
            type = "Intern";
            break;
          default:
            type = "";
            break;
        }
        return type;
      },
      set: function() {}
    },
    isWorry: {
      get: function () {
        return this.jobData.isWorry?"不是":"是";
      },
      set: function () {}
    },
    status: {
      get: function () {
        return this.jobData.status?"开启":"关闭";
      },
      set: function () {}
    },
    labels: {
      get: function () {
        return this.jobData.labels?this.jobData.labels.split(","):[];
      },
      set: function () {}
    }
  },
  methods: {
    handleCancleClick () {
      this.$emit('_close')
    },
  }
}
</script>

<style lang="less" scoped>
.info {
  display: block;
  max-width: unset;
  font-size: 14px;
  line-height: 25px;
  color: gray;
  margin-top: 10px;
  padding: 8px;
  background: #fcfcfc;
  border: 1px #ccc dotted;
  white-space: normal;
}
</style>