<template>
  <a-drawer class="recruit-add" title="创建招聘岗位" @close="onClose" :width="720" :visible="recruitAddVisiable" :maskClosable="false">
    <a-form-model ref="ruleForm" :rules="rules" :model="form">
      <a-form-model-item label='职位名称' prop="jobName" v-bind="formItemLayout">
        <a-input v-model="form.jobName" class="input-style"></a-input>
      </a-form-model-item>
      <a-form-model-item label='工作简介' prop="jobDescription" v-bind="formItemLayout">
        <!-- <a-textarea placeholder="工作简介" v-model="form.jobDescription" class="input-style" :rows="4" /> -->
        <editor name="jobDescription" :value.sync="form.jobDescription" :height="300" @update-value="updateValue"></editor>
      </a-form-model-item>
      <a-form-model-item label='岗位要求' prop="requirements" v-bind="formItemLayout">
        <editor name="requirements" :value.sync="form.requirements" :height="300" @update-value="updateValue"></editor>
      </a-form-model-item>
      <a-form-model-item label='职位亮点' prop="highlights" v-bind="formItemLayout">
        <a-textarea placeholder="职位亮点" v-model="form.highlights" class="input-style" :rows="4" />
      </a-form-model-item>
      <a-form-model-item label='职位标签' prop="labels" v-bind="formItemLayout">
        <a-textarea placeholder="多个标签以 , 分割" v-model="form.labels" class="input-style" :rows="4" />
      </a-form-model-item>
      <a-form-model-item label='部门' prop="deptId" v-bind="formItemLayout">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptTreeData"
          placeholder="选择部门"
          @change="handleAcceptDeptChange"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <!-- <a-form-model-item label='工作年限' prop="experience" v-bind="formItemLayout">
        <a-input v-model="form.experience" class="input-style"></a-input>
      </a-form-model-item>
      <a-form-model-item label='学历' prop="degree" v-bind="formItemLayout">
        <a-input v-model="form.degree" class="input-style"></a-input>
      </a-form-model-item> -->
      <a-form-model-item label='招聘人数' prop="headCount" v-bind="formItemLayout">
        <a-input-number :precision="0" v-model="form.headCount" :min="1" :max="100" />
      </a-form-model-item>
      <a-form-model-item label='职位类别' prop="jobType" v-bind="formItemLayout">
        <a-select v-model="form.jobType" style="width: 120px">
          <a-select-option value="1">SR</a-select-option>
          <a-select-option value="2">Hourly</a-select-option>
          <a-select-option value="3">Intern</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label='急招' prop="isWorry" v-bind="formItemLayout">
        <a-radio-group v-model="form.isWorry">
          <a-radio value="0">否</a-radio>
          <a-radio value="1">是</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label='职位状态' v-bind="formItemLayout">
        <div style="text-align: left;">
          <a-tag :color="disabled?'#87d068':'red'">{{ disabled?"启用":"禁用" }}</a-tag>
          <a-switch size="small" :checked="disabled" @change="handleDisabledChange" />
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import Editor from "../../../components/editor/editor"
import DeptInputTree from "../../system/dept/DeptInputTree";
import {getDeptRootId} from './../../../utils/tools'

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}

export default {
  name: "JobAdd",
  components: {Editor,DeptInputTree},
  props: {
    recruitAddVisiable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deptTreeData: [],
      formItemLayout,
      disabled: false,
      form: {
        jobName: "",
        jobDescription: "",
        jobType: "",
        isWorry: '0',
        status: "0",
        headCount: "",
        requirements: "",
        labels: undefined,
        deptId: undefined,
        experience: undefined,
        degree: undefined,
        highlights: undefined
      },
      rules: {
        jobName: [
          { required: true, message: '职位标题不能为空', trigger: 'blur' }
        ],
        jobDescription: [
          { required: true, message: '工作简介不能为空', trigger: 'blur' }
        ],
        jobType: [
          { required: true, message: '职位类型不能为空', trigger: 'blur' }
        ],
        headCount: [
          { required: true, message: '招聘人数不能为空', trigger: 'blur' }
        ],
        jobDescription: [
          { required: true, message: '工作简介不能为空', trigger: 'change' }
        ],
        requirements: [
          { required: true, message: '岗位要求不能为空', trigger: 'change' }
        ],
        deptId: [
          { required: true, message: '部门不能为空', trigger: 'change' }
        ],
        // experience: [
        //   { required: true, message: '工作年限不能为空', trigger: 'blur' }
        // ],
        // degree: [
        //   { required: true, message: '学历不能为空', trigger: 'blur' }
        // ],
      }
    }
  },
  computed: {
    topDeptId() {
      return getDeptRootId('inner')
    },
  },
  mounted() {
    this.$get("dept", { rootDeptIds: this.topDeptId }).then((r) => {
      this.deptTreeData = r.data.rows.children;
    });
  },
  methods: {
    onClose () {
      this.$refs.ruleForm.resetFields();
      this.$emit('close');
    },
    handleSubmit() {
      // console.log("要提交的值", {...this.form});
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$post("recruit", {...this.form}).then(r => {
            // console.log("招聘岗位创建成功", r.data);
            this.$emit('success');
          });
        } else {
          return false;
        }
      });
    },
    handleDisabledChange(val) {
      this.disabled = val;
      this.form.status = val?'1':'0';
    },
    updateValue(val, key='') {
      // console.log("key: ", key, "val: ", val);
      this.form[key] = val;
    },
    handleAcceptDeptChange(value, label) {
      console.log("deptId ==>", value, label[0]);
      this.form.deptId = +value;
    },
  }
}
</script>

<style lang="less" scoped>

</style>